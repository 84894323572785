import { graphql, useStaticQuery } from 'gatsby'
import React, { useEffect } from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'

const NotFoundPage = () => {
  const {
    site: {
      siteMetadata: { title },
    },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  useScript(`
    (function(w,a){
    w[a]= (w[a]=w[a]||[]);
    var ec3idMatched = /[?&]ec3id=([^&#]+)/g.exec(location.href);
    if (ec3idMatched && ec3idMatched.length > 1) {
      w[a].push({type:'identify',value:ec3idMatched[1]});
    }
    })(window,'clLayers')
  `)

  useScript(`
  (function () {
    var slots = [{
                  "context_name":"keibi_flag",
                      "value": "警備用",
                  }
   ];
              var s = document.createElement('script');
              s.id = 'bedore-webagent';
              s.src = 'https://webagent.bedore.jp/init.js';
              s.charset = 'utf-8';
              s.setAttribute('data-token', 'c3c8a30b-8c75-4fc8-9eb7-d5a716ca0e1d');
              s.setAttribute('data-slots', JSON.stringify(slots));
              document.getElementById('content').appendChild(s);
          }());
 `)

  return (
    <Layout>
      <Helmet>
        <html className="template" lang="ja" />
        <title>{`駐車場トラブル　有人チャットでご質問 | ${title}`}</title>
      </Helmet>
      <div id="content" />
    </Layout>
  )
}

const useScript = (body) => {
  useEffect(() => {
    const script = document.createElement('script')
    script.innerHTML = body
    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
    }
  }, [body])
}

export default NotFoundPage
